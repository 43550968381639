import { connect } from 'react-redux'
import 'url-search-params-polyfill'
import PropTypes from 'prop-types'
import { addAccountApp, deleteAccountApp, showBanner, hideBanner, loadAllApps, checkGAStatus, loadGAProperties, switchAccountApp, loadAccountUserSettings, updateAccountUserSettings } from '../../actions/actions'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import AddAccountApp from '../../components/accountApps/AddAccountApp'
import AccountAppCard from '../../components/accountApps/AccountAppCard'
import AccountUserSettings from '../../components/accountUserSettings/AccountUserSettings'
import Grid from '@material-ui/core/Grid'
import { Enums } from 'utils'
import { Redirect, Link } from 'react-router-dom'
import SubAppBar from '../../components/navigation/SubAppBar'
import Button from '@material-ui/core/Button'
import AppSearch from '../../components/apps/AppSearch'

class LoggedInHomeContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      addingAccountApp: false,
      addingCompetitor: null,
      isappfound: true
    }
    this.onAdd = this.onAdd.bind(this)
    this.onDelete = this.onDelete.bind(this)
    this.onQuickLookup = this.onQuickLookup.bind(this)
  }

  componentDidMount() {
    const hasHitLimit = this.hasHitLimit(this.props)
    if (hasHitLimit === true) {
      this.showLimitBanner()
    } else {
      const query = new URLSearchParams(this.props.location.search)
      const adding = query.get('add')
      if (adding !== null) {
        this.props.history.replace(this.props.location.pathname)
        this.setState({
          addingAccountApp: true
        })
      }
    }

    if (this.props.apps.all.data === null && this.props.apps.all.isLoading === false) {
      this.props.dispatch(loadAllApps())
    }

    if (this.props.googleAnalytics.status === Enums.GAStatus.NotChecked && this.props.googleAnalytics.status !== Enums.GAStatus.Checking) {
      this.props.dispatch(checkGAStatus(this.props.accounts.currentAccountId))
    } else if (this.props.googleAnalytics.status === Enums.GAStatus.Authed && this.props.googleAnalytics.properties.data.length < 1 && this.props.googleAnalytics.properties.isLoading === false) {
      this.props.dispatch(loadGAProperties(this.props.accounts.currentAccountId))
    }

    if (this.props.accountUserSettings.isLoading === false && this.props.accountUserSettings.emailUpdatesFrequencyDays === null) {
      this.props.dispatch(loadAccountUserSettings(this.props.accounts.currentAccountId))
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.accountApps.isLoading === true && this.props.accountApps.isLoading === false && (!this.props.accountApps.errors || this.props.accountApps.errors === null) && this.state.addingAccountApp === true) {
      this.setState({ addingAccountApp: false })
    }
    if (this.hasHitLimit(prevProps) !== this.hasHitLimit(this.props)) {
      if (this.hasHitLimit(this.props) === true) {
        this.showLimitBanner()
      } else {
        this.props.dispatch(hideBanner())
      }
    }
    if (this.props.googleAnalytics.status === Enums.GAStatus.Authed && prevProps.googleAnalytics.status !== Enums.GAStatus.Authed) {
      // just authed OR just checked and is authed BUG? when changing auth
      this.props.dispatch(loadGAProperties(this.props.accounts.currentAccountId))
    }
  }

  componentWillUnmount() {
    this.props.dispatch(hideBanner())
  }

  showLimitBanner() {
    return // testing less invasive upgrade asks

    this.props.dispatch(
      showBanner(
        <React.Fragment>Your plan includes {this.props.accounts.currentAccount.appsLimit} apps. Upgrade your plan if you'd like to track more apps.</React.Fragment>,
        null,
        <Button variant="outlined" color="secondary" component={Link} to='/l/billing' style={{ whiteSpace: 'nowrap' }}>
          Upgrade your plan
        </Button>
      ))
  }

  onAdd(appsId, competitor, googleAnalyticsProperty, url, name) {
    const appType = competitor === true ? Enums.AppType.Competitor : Enums.AppType.Own
    this.props.dispatch(addAccountApp(
        this.props.accounts.currentAccountId,
        appsId,
        appType,
        googleAnalyticsProperty,
        null,
        null,
        null,
        url,
        name))
  }

  onSelectAppToAdd(newValue) {
    this.setState({ selectedAppToAdd: newValue })
  }

  onDelete(accountAppId) {
    this.props.dispatch(deleteAccountApp(accountAppId))
  }

  hasHitLimit(props) {
    return props.accountApps.forCurrentAccount.filter(x => x.appType !== Enums.AppType.Lookup).length + 1 > this.props.accounts.currentAccount.appsLimit
  }

  isLastApp() {
    return this.props.accountApps.forCurrentAccount.filter(x => x.appType !== Enums.AppType.Lookup).length < 2
  }

  onQuickLookup(e, selectedApp) {
    this.props.history.push(`/l/apps/${selectedApp.url.replace('https://','').replace('http://', '').replace('apps.shopify.com/', '')}/listing-history`)
  }

  render() {
    if (this.props.accountApps.forCurrentAccount.filter(x => x.appType !== Enums.AppType.Lookup).length < 1) {
      return <Redirect to='/l/setup' />
    }

    const hitLimit = this.hasHitLimit(this.props)

    let ownApps = []
    let competitorApps = []
    this.props.accountApps.forCurrentAccount.forEach((accountApp) =>
      {
        let component = (
          <Grid key={accountApp.tempId && accountApp.tempId !== null ? accountApp.tempId : accountApp.id} item xs={12} md={6} lg={4}>
            <AccountAppCard
              accountApp={accountApp}
              allowDelete={this.isLastApp() === false}
              onDelete={this.onDelete}
            />
          </Grid>
        )
        if (accountApp.appType === Enums.AppType.Competitor) {
          competitorApps.push(component)
        } else if (accountApp.appType === Enums.AppType.Own) {
          ownApps.push(component)
        }
      }
    )

    return (
      <React.Fragment>
        <SubAppBar title='Home' />
        <Container>
          <Typography component='h2' variant='h6' gutterBottom style={{ marginTop: 20 }}>
            Own Apps
          </Typography>
          <Grid container spacing={2}>
            {ownApps}
            <Grid item xs={12} md={6} lg={4}>
              <AddAccountApp
                  currentAccountId={this.props.accounts.currentAccountId}
                  allApps={this.props.apps.all.data}
                  onAdded={this.onAdd}
                  gaStatus={this.props.googleAnalytics.status}
                  properties={this.props.googleAnalytics.properties}
                  definitelySkippingGAAuth={true}
                  isCompetitor={false}
                  disabled={this.props.apps.all.isLoading === true || hitLimit === true}
                  hitLimit={hitLimit} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography component='h2' variant='h6' gutterBottom  style={{ marginTop: 20 }}>
                Competitors
              </Typography>
              <AccountUserSettings
                isLoading={this.props.accountUserSettings.isLoading}
                emailUpdatesFrequencyDays={this.props.accountUserSettings.emailUpdatesFrequencyDays}
                onSelectFrequency={ (e) => this.props.dispatch(updateAccountUserSettings(this.props.accounts.currentAccountId, e.target.value))} />
            </Grid>
            {competitorApps}
            <Grid item xs={12} md={6} lg={4}>
              <AddAccountApp
                  currentAccountId={this.props.accounts.currentAccountId}
                  allApps={this.props.apps.all.data}
                  onAdded={this.onAdd}
                  properties={this.props.googleAnalytics.properties}
                  isCompetitor={true}
                  gaStatus={this.props.googleAnalytics.status}
                  disabled={this.props.apps.all.isLoading === true || hitLimit === true}
                  hitLimit={hitLimit} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography component='h2' variant='h6' gutterBottom  style={{ marginTop: 20 }}>
                Quick Lookup
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <AppSearch isLoading={this.props.apps.all.isLoading || this.props.apps.all.data === null} allApps={this.props.apps.all.data} onChange={this.onQuickLookup} appType={Enums.AppType.Lookup} forCurrentAccountApp={this.props.accountApps.forCurrentAccount} history={this.props.history} />
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { accounts, accountApps, accountUserSettings, apps, googleAnalytics } = state

  return { accounts, accountApps, accountUserSettings, apps, googleAnalytics }
}

LoggedInHomeContainer.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(LoggedInHomeContainer)
